<template>
  <div class="about" slot="component">
      <div class="about-images">
        <!-- <img class="about-images-noorPlay_logo" src="@/assets/icons/EnrichtvLog.png" alt /> -->
        <img class="about-images-noorPlay_logo" 
        :src="isThemeDark ? logoDark : logoLight" alt />
        <!-- <img v-else class="about-images-vlive-logo" src="@/assets/icons/log.svg" alt /> -->
        <!-- <img class="about-images-vlive-title" src="@/assets/icons/text.svg" alt /> -->
        <!-- <p class="about-images-version">V1.0.1</p> -->
      </div>
      <div class="about-description">
        <p
          class="about-description-paragraph"
        >{{ localDisplayLang === 'ml' ? appConfig.providerDetails.aboutText.ar : appConfig.providerDetails.aboutText.default }}</p>
      </div>
      <div class="about-social">
        <p class="about-social-follow">{{ $t('follow us') }}</p>
        <div class="about-social-icons">
          <a
            :href="appConfig.providerDetails.social.facebook"
            target="_blank"
            rel="noopener"
            aria-label="facebook link"
          >
            <img src="@/assets/icons/newfb.svg" alt="facebook_img" />
          </a>
          <a
            :href="appConfig.providerDetails.social.instagram"
            target="_blank"
            rel="noopener"
            aria-label="instagram link"
          >
            <img src="@/assets/icons/newinsta.svg" alt="instagram_img" />
          </a>
          <a
            :href="appConfig.providerDetails.social.twitter"
            target="_blank"
            rel="noopener"
            aria-label="twitter link"
          >
            <img src="@/assets/icons/newtwitter.svg" alt="twitter_img" />
          </a>
          <!-- <a
            :href="appConfig.providerDetails.social.pinterest"
            target="_blank"
            rel="noopener"
            aria-label="pinterest link"
          >
            <img src="@/assets/icons/newpinterest.svg" alt="pinterest_img" />
          </a>-->
          <a
            :href="appConfig.providerDetails.social.youtube"
            target="_blank"
            rel="noopener"
            aria-label="pinterest link"
          >
            <img src="@/assets/icons/youtube.svg" alt="youtube" />
          </a>
        </div>
      </div>
      <div class="about-app-share">
        <button
          class="button-primary"
          :class="[providerUniqueId === 'z01x1wPo' ? 'primary-nammaflix-button' : 'primary-net5-button']"
          @click="shareApp"
        >{{ $t('share the app') }}</button>
      </div>
      <p style="text-align: center; color: #868688; font-size: 12px;margin-top: 15px;">v1.0.1</p>
      <div class="about-policy-links">


        <ul style="display: flex;">
  
         
        <li>
          <router-link tabindex="1" class="text"  :to="showTermsOfUse()">
            {{ $t("terms of use") }}
          </router-link>
        </li>
        <li style="text-align: center; color: #777;">
          {{ $t("And") }}
        </li>
        <li>
            <router-link tabindex="1"  class="text"  :to="showPrivacyPolicy()">
            {{ $t("privacy policy") }}
          </router-link>
        </li>
        </ul>
        
        
        <!-- <router-link to="/TermsOfUse">
          <p @click="closeAbout">Terms Of Use</p>
        </router-link>
        <router-link to="/PrivacyPolicy">
          <p @click="closeAbout">Privacy Policy</p>
        </router-link>-->
        <!-- <router-link :to="localDisplayLang === 'ara' ? '/ar/TermsOfUse' : '/en/TermsOfUse'">
          <p>
            {{ $t('terms of use') }}
          </p>
        </router-link> -->
                        <!-- <a
              class="text"
              :href="[localDisplayLang === 'ara' ? appConfig.providerDetails.termsConditionsUrl.ar : appConfig.providerDetails.termsConditionsUrl.default ]"
              target="_blank"
            >{{ $t('terms of use') }}</a>
                         <a
              class="text"
              :href="[localDisplayLang === 'ara' ? appConfig.providerDetails.privacyPolicyUrl.ar : appConfig.providerDetails.termsConditionsUrl.default ]"
              target="_blank"
            >{{ $t('privacy policy') }}</a> -->
        <!-- <router-link :to="localDisplayLang === 'ara' ? 'https://www.ultraindia.com/privacy' : 'https://www.ultraindia.com/privacy'">
          <p>
            {{ $t('privacy policy') }}
          </p>
        </router-link> -->
      </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { eventBus } from "@/eventBus";
import { _providerId } from "@/provider-config.js";
import Utility from "@/mixins/Utility.js";

export default {
  props: {
    closePopup: {
      type: Function
    }
  },
  data() {
    return {
      width: "50%",
      margin: "",
      radiusType: "full",
      providerUniqueId: _providerId,
      localDisplayLang: null,
      logoLight:  require("@/assets/icons/sainaplaydark.png"),
      logoDark: require("@/assets/icons/sainaplaylight.png"),
      isThemeDark: true
    };
  },
mounted() {

document.title= "Saina Play"; 

},

  computed: {
    ...mapGetters(["appConfig", "getRtl"])
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    window.scrollTo(0, 0);
      
    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", data => {
      this.localDisplayLang = data;
    });
    //setting margin for userauth popup based on screen width.
    if (screen.width < 576) {
      this.margin = "45% auto";
    } else {
      this.margin = "10% auto";
    }
    eventBus.$on("theme-change", (data) =>{
      if(data == 'theme-dark'){
        this.isThemeDark = true
      }else{
        this.isThemeDark = false
      }
    })
    let currentTheme = localStorage.getItem('theme')
    if(currentTheme == 'theme-light'){
      this.isThemeDark = false
    }else{
      this.isThemeDark = true
    }
  },
  mounted() {
    var allMetaElements = document.getElementsByTagName("meta");
    document.title = "About Us - Saina Play";
    for (var metaValue = 0; metaValue < allMetaElements.length; metaValue++) {
      if (allMetaElements[metaValue].getAttribute("name") == "description") {
        allMetaElements[metaValue].setAttribute(
          "content",
          ""
        );
      }

      if (
        allMetaElements[metaValue].getAttribute("property") == "og:description"
      ) {
        allMetaElements[metaValue].setAttribute(
          "content",
          ""
        );
      }

      if (
        allMetaElements[metaValue].getAttribute("name") == "twitter:description"
      ) {
        allMetaElements[metaValue].setAttribute(
          "content",
          ""
        );
      }

      if (allMetaElements[metaValue].getAttribute("name") == "keyword") {
        allMetaElements[metaValue].setAttribute(
          "content",
          "Saina Play About us" 
        );
      }

      if (allMetaElements[metaValue].getAttribute("property") == "og:title") {
        allMetaElements[metaValue].setAttribute(
          "content",
          "About Us - Saina Play"
        );
      }

      if (allMetaElements[metaValue].getAttribute("name") == "twitter:title") {
        allMetaElements[metaValue].setAttribute(
          "content",
          "About Us - Saina Play"
        );
      }
    }
  },

  methods: {
    closeAbout() {
      this.closePopup();
    },
    shareApp() {
      eventBus.$emit("show-share-app-popup", true);
    },
    showTermsOfUse() {
      let currentLanguage = this.getCurrentLanguageUrlBase();
      if(!window.PROVIDER_SETUP_CONFIG.isTermsEnabled){
        return;
      }

      return({name: 'termsofuse', params: {lang: currentLanguage}});
  },
  showPrivacyPolicy() {
      let currentLanguage = this.getCurrentLanguageUrlBase();
      if(!window.PROVIDER_SETUP_CONFIG.isPrivacyEnbaled){
        return;
      }

       return({name: 'privacypolicy', params: {lang: currentLanguage}});

    },
  },
  mixins: [Utility],

};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "./About.scss";
</style>
